<template>
    <div class="inner-section">
      <card>
        <!-- search section start -->
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('elearning_config.faq_setup') }}</h4>
        </template>
        <template v-slot:searchBody>
          <b-row>
            <b-col lg="6" sm="12">
                <b-form-group
                label-for="faq_setup"
                >
                <template v-slot:label>
                  {{ $t('elearning_config.faq_setup') }}
                </template>
                <v-select name="faq_setup"
                v-model="search.id"
                label="text"
                :reduce="item => item.value"
                :options= faqSetupLists
                />
                </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
                <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
              </b-button>
            </b-col>
          </b-row>
        </template>
        <!-- search section end -->
      </card>
      <body-card>
        <!-- table section start -->
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('elearning_config.faq_setup') }} {{ $t('globalTrans.list') }}</h4>
        </template>
        <template v-slot:headerAction>
          <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
        </template>
        <template v-slot:body>
          <b-overlay :show="loadingState">
            <base-table v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :componentId="3" :columns="columns" :search="search" :searchData="searchData" :paginations="pagination" :actions="actions" />
          </b-overlay>
        </template>
        <!-- table section end -->
      </body-card>
      <b-modal id="modal-form" size="md" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <Form :id="editItemId" :key="editItemId"/>
      </b-modal>
    </div>
  </template>
  <script>
  import Form from './Form.vue'
  import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
  import { faqSetupList, faqSetupToggleStatus } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  export default {
    mixins: [ModalBaseMasterList],
    components: {
      Form
    },
    data () {
        return {
            sortBy: '',
            search: {
                id: 0,
                org_id: 0,
                limit: 20
            },
            labelData: [
                { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '10%' } },
                { labels: 'elearning_config.faq_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '20%' } },
                { labels: 'elearning_config.description', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '25%' } },
                { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '10%' } },
                { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 5, thStyle: { width: '15%' } }
            ],
            commodityNameList: [],
            actions: {
            edit: true,
            toogle: true,
            delete: false
            }
        }
    },
    created () {
      this.loadData()
    },
    watch: {
      'search.limit': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.loadData()
        }
      }
    },
    computed: {
        columns () {
            const labelData = this.labelData
            const labels = labelData.map((item, index) => {
            return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
            })
            let keys = []
            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'serial' },
                    { key: 'faq_name_bn' },
                    { key: 'description_bn' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'serial' },
                    { key: 'faq_name' },
                    { key: 'description' },
                    { key: 'status' },
                    { key: 'action' }
                ]
            }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('elearning_config.faq_setup') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.faq_setup') + ' ' + this.$t('globalTrans.modify')
        },
        faqSetupLists: function () {
            return this.$store.state.TrainingElearning.commonObj.faqSetupList.filter(item => item.status === 1)
        },
        orgList () {
            return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
        }
    },
    methods: {
      editMethodInParent (item, type) {
        if (type === 1) {
          this.edit(item)
        } else if (type === 2) {
          this.changeStatus(12, trainingElearningServiceBaseUrl, faqSetupToggleStatus, item)
        }
      },
      searchData () {
        this.loadData()
      },
      async loadData () {
        const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, faqSetupList, params)
        if (result.success) {
          const listData = result.data.data.map((item, index) => {
            const orgObj = this.$store.state.CommonService.commonObj.componentOrgList.find(law => law.value === parseInt(item.org_id))
            const orgData = {}
              if (typeof orgObj !== 'undefined') {
                orgData.org_name = orgObj.text_en
                orgData.org_name_bn = orgObj.text_bn
              } else {
                orgData.org_name = ''
                orgData.org_name_bn = ''
              }
            return Object.assign({}, item, { serial: index }, orgData)
          })
          this.$store.dispatch('setList', listData)
          this.paginationData(result.data, this.search.limit)
        } else {
          this.$store.dispatch('setList', [])
          this.paginationData([])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    }
  }
  </script>
